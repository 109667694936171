<template>
  <div
    :label="label"
    class="category-cascader-wrap"
    :style="{ height: height + 'px' }"
  >
    <category-seller-select
      v-if="lists.length > 0"
      ref="addressSelect"
      :province-list="lists"
      :is-show-checked="isShowChecked"
      :disabled="disabled"
      :title-arr="titleArr"
      :height="height"
      :tag="tag"
      @change="changeDestination"
    />
    <!-- <input
      v-model.trim="form.destRegionalIdListJson"
      name="destRegionalIdListJson"
    > -->
    <!-- <span v-show="errors.has('destRegionalIdListJson')" class="error-msg">{{
      errors.first('destRegionalIdListJson')
    }}</span>
    <no-data v-show="lists.length <= 0" class="no-data" /> -->
  </div>
</template>
<script>
import Mixins from './mixins';
import { getCategoryAndParentVOList } from '@/api/user/list';
export default {
  name: 'CategorySelect',
  components: {
    // noData: resolve => require(['./nodata'], resolve),
    // popFormItem: resolve => require(["../../common/item/PopFormItem"], resolve),
    CategorySellerSelect: resolve => require(['./SellerSelect'], resolve)
  },
  mixins: [Mixins],
  props: {
    label: {
      type: String,
      default: ''
    },
    isShowChecked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    titleArr: {
      type: Array,
      default() {
        let that = this;
        return [
          // that.$t('common.category.level1'),
          'level1',
          'level2',
          'level3'
          // that.$t('common.category.level2'),
          // that.$t('common.category.level3')
        ];
        // return [that.$t("common.category.level1"), that.$t("common.category.level2"), that.$t("common.category.level3")];
      }
    },
    height: {
      type: Number,
      default: 280
    },
    tag: {
      type: String,
      default: ''
    },
    mock: {
      type: String,
      default: ''
    },
    selectnodedata: {
      type: Array
    }
  },
  data() {
    return {
      form: {},
      lists: [],
      invertLists: [],
      allSelectLists: [],
      lastSelectLists: [],
      allSelect: true
    };
  },
  watch: {
    selectnodedata: function() {
      this.requestLists2(this.selectnodedata);
    }
  },
  created() {
    // this.requestLists2(this.selectnodedata);
    this.getLists();
  },
  methods: {
    changeDestination(allSelectLists, allSelect) {
      let arr = [];
      if (allSelectLists && allSelectLists[2]) {
        allSelectLists[2].forEach(item => {
          arr.push(item.value);
        });
      }
      this.$set(
        this.form,
        'destRegionalIdListJson',
        arr.length > 0 ? JSON.stringify(arr) : ''
      );
      const lastSelectLists = this.form.destRegionalIdListJson || '[]';
      this.allSelect = allSelect;
      this.allSelectLists = allSelectLists;
      this.lastSelectLists = JSON.parse(lastSelectLists);
      this.$emit(
        'change',
        this.allSelectLists,
        this.lastSelectLists,
        this.lists,
        this.allSelect
      );
      this.$emit('allSelectList', this.lastSelectLists);
    },
    getAllSelectLists() {
      let lists = this.lists || [];
      let allSelect = this.allSelect;
      let allSelectLists = this.allSelectLists || [];
      let lastSelectLists = this.lastSelectLists || [];
      let ls = {
        lists: lists,
        allSelect: allSelect,
        allSelectLists: allSelectLists,
        lastSelectLists: lastSelectLists
      };
      return ls;
    },
    clearSelect() {
      this.$refs.addressSelect && this.$refs.addressSelect.clearSelect();
    },
    submit() {
      this.$refs.addressSelect && this.$refs.addressSelect.submit();
    },
    dataModel(res, disabled) {
      res.data.forEach(item => {
        item.checked = false;
        item.disabled = disabled;
        item.value = item.id;
        item.childList.forEach(subItem => {
          subItem.checked = false;
          subItem.disabled = disabled;
          subItem.value = subItem.id;
          subItem.childList.forEach(lastItem => {
            lastItem.checked = false;
            lastItem.disabled = disabled;
            lastItem.value = lastItem.id;
          });
        });
      });
      return res;
    },
    getLists(opts) {
      if (!this.lists.length) {
        this.allSelect = false;
        return getCategoryAndParentVOList().then(res => {
          const _res = this.dataModel(res, this.disabled);
          if (_res.code === 200) {
            const _lists = _res.data;
            this.uncheckedAll(_lists);
            this.lists = _lists;
            // if (opts && (opts.promoId || opts.couponId || opts.voucherId)) {
            //   return this.requestLists(opts);
            // }
            // this.configLists(opts);
            // console.log(this.selectnodedata);
            // setTimeout(() => {}, 4000);
          } else {
            // this.failed();
            this.lists = [];
          }
          return this.getAllSelectLists();
        });
      } else {
        return this.requestLists(opts);
      }
    },
    requestLists(opts) {
      if (!opts) {
        return Promise.resolve(this.lists);
      }
      if (this.invertLists.length) {
        this.clearSelect();
      }

      let params = {
        promoType: opts.promoType,
        promoSubType: opts.promoSubType
        // promoId: opts.promoId,
        // couponId: opts.couponId, // 优惠码particulars
        // voucherId: opts.voucherId, // 优惠券particulars
      };

      let getDetail = null;

      if (opts.couponId) {
        params.couponId = opts.couponId;
        getDetail = this.$fetch.apiPromo.getCouponDetail;
      } else if (opts.voucherId) {
        params.voucherId = opts.voucherId;
        getDetail = this.$fetch.apiPromo.getVoucherDetail;
      } else if (opts.promoId) {
        params.promoId = opts.promoId;
        getDetail = this.$fetch.apiPromo.getPromoDetail;
      } else {
        return false;
      }
      this.allSelect = true;
      // getCouponDetail
      return getDetail(params).then(res => {
        if (res.code === 0 && res.data.productDimension === 3) {
          this.checkedAll(this.lists);
          let lists = JSON.parse(JSON.stringify(this.lists));
          this.lists = [];
          return new Promise(resolve => {
            this.$nextTick(() => {
              this.lists = lists;
              resolve(this.getAllSelectLists());
            });
          });
        } else if (
          res.code === 0 &&
          res.data.categoryTreeNodeVoList &&
          res.data.categoryTreeNodeVoList.length
        ) {
          const lists = this.configLists(res.data);
          this.invertLists = lists;
          this.lists = [];
          return new Promise(resolve => {
            this.$nextTick(() => {
              this.lists = this.invertLists;
              resolve(this.getAllSelectLists());
            });
          });
        } else {
          this.lists = [];
          // this.failed();
          return this.getAllSelectLists();
        }
      });
    },
    configLists(data) {
      let bases = this.deepClone(this.lists);
      let lists = data;
      this.recursionCategorys(lists);
      this.recursionBasesToCategory(bases, lists);
      this.isHalfChecked(bases);
      return bases;
    },
    recursionCategorys(categorys) {
      if(categorys.length){
        for (let i = 0; i < categorys.length; i++) {
          const category = categorys[i];
          if (category.id) {
            category.checked = true;
            this.allSelectLists.push(category.id);
          } else {
            this.allSelect = false;
          }
          if (category.childList) {
            this.recursionCategorys(category.childList);
          }
        }
      }
      
    },
    recursionBasesToCategory(bases, lists, checked) {
      bases.forEach(base => {
        if (checked) {
          base.checked = checked;
          if (base.childList) {
            this.recursionBasesToCategory(base.childList, [], true);
          }
        } else {
          lists.forEach(list => {
            if (base.id === list.id && list.checked) {
              this.allSelectLists.push(base.id);
              base.checked = list.checked;
            } else {
              this.allSelect = false;
            }
            if (base.checked && base.childList) {
              if (list.childList && list.childList.length > 0) {
                this.recursionBasesToCategory(
                  base.childList,
                  list.childList,
                  false
                );
              } else if (base.checked && base.id === list.id) {
                this.recursionBasesToCategory(base.childList, [], true);
              }
            }
          });
        }
      });
    },
    requestLists2(data) {
      const lists = this.configLists(data);
      this.invertLists = lists;
      this.lists = [];
      return new Promise(resolve => {
        this.$nextTick(() => {
          this.lists = this.invertLists;
          resolve(this.getAllSelectLists());
        });
      });
    },
    checkedAll(list) {
      list.forEach(item => {
        item.checked = true;
        if (item.childList) {
          this.checkedAll(item.childList);
        }
      });
    },
    uncheckedAll(list) {
      list.forEach(item => {
        item.checked = false;
        if (item.id) {
          item.value = item.id;
        }
        if (item.pId) {
          item.pValue = item.pId;
        }
        if (item.childList) {
          this.uncheckedAll(item.childList);
        }
      });
    },
    isHalfChecked(bases) {
      bases.forEach(base => {
        if (base.checked && base.childList) {
          let half = base.childList.every(item => {
            return item.checked;
          });
          base.halfChecked = !half;
        }
        if (base.checked && base.childList && base.childList.length) {
          this.isHalfChecked(base.childList);
        }
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
.category-cascader-wrap
    width 100%;
    height 100%;
    >>> .form-control
        width 100% !important;
        height 100% !important;

.no-data
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
</style>
