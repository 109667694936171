<template>
  <div class="poolList">
    <div class="serch_box">
      <div class="box-top">
        <el-form
          :inline="true"
          label-width="150px"
          class="user-search fixed_form"
        >
          <el-form-item label="Product Pool No" size="mini" class="lableHeight">
            <template>
              <el-input
                v-model.trim="form.id"
                onkeyup="value=value.replace(/[^\d]/g,'')"
                placeholder="Please Enter"
                size="mini"
                maxlength="100"
              />
            </template>
          </el-form-item>
          <el-form-item
            label="Product Pool Name"
            size="mini"
            class="lableHeight"
          >
            <template>
              <el-input
                v-model.trim="form.poolName"
                placeholder="Please Enter"
                size="mini"
                maxlength="100"
              />
            </template>
          </el-form-item>
          <!-- Creator -->
          <el-form-item
            label="Creator"
            size="mini"
            prop="creator"
          >
            <template>
              <el-input
                v-model.trim="form.creator"
                style="width: 187px"
                size="mini"
                maxlength="100"
                placeholder="please Enter"
              />
            </template>
          </el-form-item>
          <el-form-item v-show="hide" label="Enterprise Name:" size="mini">
            <template>
              <el-select v-model="enterpriseNameCode" popper-class="enterpriseNameClass" filterable placeholder="Please enter company name" @change="changeEnterpriseFn">
                <el-option
                  v-for="item in EnterpriseList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </template>
          </el-form-item>
          <el-form-item v-show="hide" label="Contact NO" size="mini">
            <el-input
              v-model.trim="form.contractNo"
              width="187px"
              size="mini"
              maxlength="100"
              placeholder="please Enter"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                style="width:30px"
                @click="showSku"
              />
            </el-input>
          </el-form-item>
          <!-- Modifier -->
          <el-form-item
            v-show="hide"
            label="Modifier"
            size="mini"
            maxlength="100"
          >
            <template>
              <el-input
                v-model.trim="form.modifier"
                size="mini"
                onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
                placeholder="please Enter"
              />
            </template>
          </el-form-item>
          <!-- SKU ID -->
          <!-- <el-form-item v-show="hide" label="SKU ID" size="mini">
            <template>
              <el-input
                v-model.trim="form.skuId"
                width="187px"
                size="mini"
                oninput="value=value.replace(/[^\d\,]/g,'')"
                maxlength="100"
                placeholder="please Enter"
              />
            </template>
          </el-form-item> -->
         
          <!-- Create Time -->
          <el-form-item v-show="hide" label="Create Time" size="mini">
            <template>
              <el-date-picker
                v-model.trim="form.created"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="option date"
              />
            </template>
          </el-form-item>
          <el-form-item v-show="hide" label="Modify Time" size="mini">
            <template>
              <el-date-picker
                v-model.trim="form.modified"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="option date"
              />
            </template>
          </el-form-item>
          <!-- <el-form-item v-show="hide" label="Enterprise Name:" size="mini">
            <template>
              <el-input
                v-model.trim="form.enterpriseName"
                placeholder="please enter Enterprise Name"
                size="mini"
                maxlength="100"
              />
            </template>
          </el-form-item> -->
        </el-form>
        <div class="button-box">
          <!-- 搜索按钮 -->
          <div class="xiala">
            <a href="javascript:;" @click="selectHiden"><span> MoreOptions</span>
              <i
                :class="
                  hide == true ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                "
                style="float: right"
              /></a>
          </div>
          <div class="button-right">
            <el-button size="mini" style="width: 100px" @click="offBtn">
              Reset
            </el-button>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="searchBtn"
            >
              Search
            </el-button>
          </div>
        </div>
      </div>
      <div class="buttonr">
        <el-button
          size="mini"
          class="el-icon-plus"
          type="primary"
          @click="addCategorySku"
        >
          Batch Add SKU
        </el-button>
        <el-button
          size="mini"
          class="el-icon-plus"
          :disabled="disabled"
          @click="addCategory"
        >
          Add Category SKU
        </el-button>
        <el-select
          v-model.trim="value"
          class="CategoryOption"
          size="small"
          placeholder="Batch Delete SKU"
          :disabled="DeleteSku"
          @change="changes"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-button v-if="false" size="mini" class="exportExcel" @click="exportExcel">
          Export Excel
        </el-button>
      </div>
      <!-- 表格数据 -->
      <div class="panel-body">
        <el-table
          ref="multipleTable"
          :header-cell-style="{ background: '#e8f1fe' }"
          :data="tableList"
          style="width: 100%"
          @select-all="selecall"
          @selection-change="handleSelectionChange"
        >
          <!-- 修改无数据时 提示文字 -->
          <template slot="empty" class="logo_no">
            <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
            <p style="color: #969799">No more data</p>
          </template>
          <!-- 选择框 -->
          <el-table-column type="selection" width="55" fixed="left" />
          <!-- Product Pool No -->
          <el-table-column
            props="id"
            label="Product Pool No"
            width="150"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.id || '--' }}</span>
            </template>
          </el-table-column>
          <!-- Product Pool Name -->
          <el-table-column
            prop="poolName"
            label="Product Pool Name"
            width="210"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.poolName || '--' }}</span>
            </template>
          </el-table-column>
          <!--  Remarks -->
          <el-table-column
            prop="Remarks"
            label="Remarks"
            width="160"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.remark || '--' }}</span>
            </template>
          </el-table-column>
          <!-- Product Pool Type -->
          <el-table-column
            v-if="!ifTH"
            prop="skuId"
            label="SKU ID"
            width="160"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.vi_type || '--' }}</span>
            </template>
          </el-table-column>
          <!-- Contract No -->
          <el-table-column
            prop="contractNo"
            label="Contract No"
            min-width="200"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.contractNo || '--' }}</span>
            </template>
          </el-table-column>
          <!-- Enterprise ID -->
          <el-table-column
            prop="date"
            label="Enterprise Name"
            width="150"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.enterpriseName || '--' }}</span>
            </template>
          </el-table-column>
          <!-- Creator -->
          <el-table-column
            label="Creator"
            min-width="200"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.creator || '--' }}</span>
            </template>
          </el-table-column>
          <!-- Modifler -->
          <el-table-column
            label="Modifler"
            width="200"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.modifier || '--' }}</span>
            </template>
          </el-table-column>
          <!-- Create Time -->
          <el-table-column
            label="Create Time"
            width="160"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.created }}</span>
            </template>
          </el-table-column>
          <!-- Modify Time -->
          <el-table-column
            label="Modify Time"
            width="160"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.modified }}</span>
            </template>
          </el-table-column>
          <!-- Operate -->
          <el-table-column label="Operate" min-width="133" fixed="right">
            <template slot-scope="scope">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  More<i class="el-icon-arrow-down el-icon--right" />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <span @click="modify(scope.row)">Modify</span>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <span @click="Delete(scope.row)">Delete</span>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <span @click="Copy(scope.row)">Copy</span>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <span @click="Export(scope.row)">Export</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页信息 -->
      <div class="pageWrapper">
        <el-pagination
          :current-page="listQuery.page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="listQuery.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="listQuery.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <!-- 弹框 -->
    <!-- Batch Add SKU to Product Pool -->
    <el-dialog
      :title="poolTitle"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <div>
        Selected Pool List:<span style="margin-left: 5px">{{
          String(spanId)
        }}</span>
        <div style="margin-top: 20px">
          <el-form class="From">
            <el-form-item label="SKU" required class="fromButton">
              <el-upload
                style="float: right"
                class="upload-demo"
                :action="http"
                :on-success="handleAvatarSuccess"
                multiple
              >
                <el-button size="small" class="el-icon-folder">
                  Fill NO From Excel
                </el-button>
              </el-upload>
              <el-button class="sl-icon-download" @click="DownExcel()">
                Download Excel Template
              </el-button>
            </el-form-item>
          </el-form>
          <textarea
            v-model.trim="skuID"
            oninput="value=value.replace(/[^\d\,]/g,'')"
            style="outline: none"
            class="dialogTextarea"
            placeholder="Seperate multiple inputs with commas. You can also input by “Fill sku from excel”button below."
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="BatchaddCancel()">Cancel</el-button>
        <el-button v-show="buttonAdd" type="primary" @click="Confirm()">Confirm</el-button>
        <el-button
          v-show="buttonDelete"
          type="primary"
          class="sl-icon-delete"
          @click="DeleteConfirm()"
        >Confirm to Delete</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="centerDialogVisible"
      center
      width="70%"
      :before-close="centerDialogVisibleClose"
    >
      <div>
        <CategorySelect
          ref="allSelectList"
          :selectnodedata="datas"
          @allSelectList="allSelectLists"
        />
      </div>
      <div class="span">
        Selected 3rd-Categories:<span>{{ allSelectListsr.length }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAllSlelectLit">cancel</el-button>
        <el-button type="primary" @click="updateCates">confirm</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Choice SKU" :visible.sync="dialogSKU" width="50%">
      <Delotal @handleCurrentChanges="handleCurrentChanges" />
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogSKU = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="updateDatas('postForm')"
        >
          Confirm
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getProductList,
  poolExports,
  poolOneExports,
  updateCate,
  deleteCate,
  download,
  addPoolSkuIdss,
  removePoolAndSkuRelations,
  DeleteSku,
  getCategoryTree
} from '@/api/ProductPool/api';
import CategorySelect from '@/components/categoryCascader/categoryCascader/CategorySelectPool.vue';
const defaultForm = {
  Operator: '',
  ContractNo: '',
  ProductPoolSKU: '',
  ProdcutPoolName: ''
};
import Delotal from '../../contractManage/conttemplat.vue';
import { getEnterpriseName } from '@/api/enterprise/enterprise';
export default {
  inject: ['reload'],
  name: 'PoolList',
  components: {
    CategorySelect,
    Delotal
  },
  data() {
    return {
      ifTH: false,
      emptyImgSrc: '',
      // 控制弹框
      dialogVisible: false,
      //控制下拉显示影藏
      hide: true,
      // create time
      createValue: '',
      idnex: 1,
      // 配置分页信息
      listQuery: {
        page: 1,
        pageSize: 10,
        // 总数据条数
        total: 0
      },
      form: {},
      // 后端获取表格信息
      tableList: [],
      // 选中列表信息项
      multipleSelection: [],
      // Type的下拉框
      nameValue: '',
      //按钮下拉
      options: [
        {
          value: '1',
          label: 'Batch Delete SKU'
        },
        {
          value: '2',
          label: 'Delete Category SKU',
          disabled: true
        }
      ],
      value: '',
      centerDialogVisible: false,
      allSelectListsr: [], //弹框三级类目
      disabled: true, //禁用点开三级类目
      spanId: [], //选中回填的id
      excel: 'addskuinfoTemplate.xlsx',
      http:
        process.env.VUE_APP_BASE_API +
        '/system/sku/pool/batchAddPoolSkuIdsFile', //地址
      skuID: null,
      DeleteSku: true,
      datas: [],
      // 显示的添加和删除的标题
      poolTitle: '',
      // 批量删除
      buttonDelete: false,
      // 批量添加
      buttonAdd: true,
      multipleSelection2: [],
      dialogSKU: false,
      multipleSelections: '',
      addCategoryDialog:false,
      EnterpriseList: [],
      enterpriseNameCode: '',
    };
  },
  computed: {},
  watch: {
    dialogFormVisible: function() {
      this.$refs['postForm'].resetFields();
    },
    multipleSelection() {
      if (
        this.multipleSelection.length >= 2 ||
        this.multipleSelection.length <= 0
      ) {
        this.disabled = true;
        this.DeleteSku = true;
      }
    }
  },
  created() {
    if (process.env.VUE_APP_BASE_API == '/apiid') {
      this.ifTH = false;
    } else if (process.env.VUE_APP_BASE_API == '/apith') {
      this.ifTH = true;
    }
    this.getFirstData();
    this.getEnterpriseNameData();
    this.emptyImgSrc = '';
  },
  mounted() {
    this.selectHiden();
  },
  methods: {
    // 切换企业名称，回显合同号
    changeEnterpriseFn(val) {
      this.form.contractNo = val;
    },
    // 根据企业名称查询 合同号
    getEnterpriseNameData() {
      getEnterpriseName().then(res => {
        if (res.code === 200) {
          var list = [];
          if (res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              const element = res.data[i];
              if (element.contractNo != null) {
                list.push({
                  value: element.contractNo,
                  label: element.enterpriseName,
                  id: element.id
                });
              }
            }
            this.EnterpriseList = list;
          } else {
            this.EnterpriseList = [];
          }
        }
      });
    },
    showSku() {
      this.dialogSKU = true;
    },
    handleCurrentChanges(val) {
      console.log(val);
      this.multipleSelections = val;
    },
    updateDatas() {
      console.log(this.multipleSelections);

      this.form.contractNo = this.multipleSelections.contractNo;
      this.dialogSKU = false;
    },
    //取消添加类目弹窗
    cancelAllSlelectLit() {
      this.centerDialogVisible = false;
      // this.toggleSelection();
      // this.value = '';
      // this.$refs.allSelectList.allSelectLists = [];
      // this.$refs.allSelectList.clearSelect();
    },
    // 搜索栏-清空按钮
    offBtn() {
      this.reload();
      this.enterpriseNameCode = '';
    },
    // 搜索栏-搜索按钮
    searchBtn() {
      this.listQuery.page = 1;
      this.getFirstData();
    },
    // 获取页面数据
    getFirstData() {
      getProductList(
        Object.assign(
          {
            pageSize: this.listQuery.pageSize,
            pageNo: this.listQuery.page
          },
          this.form
        )
      ).then(res => {
        if (res.code === 200) {
          this.tableList = res.data.dataList;
          this.listQuery.total  = res.data.totalCount;
        } else {
          this.tableList = [];
          this.listQuery.total  = 0;
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    // 取消选中的状态
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    addBtn() {
      this.$router.push({ name: 'Commodity pools' });
    },
    handleUpdate(row) {
      this.$router.push({ name: 'Commodity pools',query: { flang:row.flang,id:row.id,contractNo:row.contractNo } });
    },
    //列表全选的时候
    selecall(val) {
      this.multipleSelection = val;
    },
    // 列表选中时触发方法
    handleSelectionChange(val) {
      this.disabled = false;
      this.DeleteSku = false;
      this.multipleSelection = val;
      this.multipleSelection2 = val;
    },
    // 分页信息-设置条数
    handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.getFirstData();
    },
    // 分页信息-切换页数
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getFirstData();
    },
    // 列表操作
    modify(row) {
      row.flang = true;
      this.$router.push({
        name: 'editCommodity pools',
        query: {
          flang:row.flang,
          id:row.id,
          contractNo:row.contractNo
        }
      });
    },
    // 列表-删除按钮
    Delete(row) {
      this.$confirm('Whether or not to delete?', 'warning', {
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel',
        type: 'warning'
      })
        .then(() => {
          console.log(row);
          DeleteSku(row.id).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: 'Operation succeeded'
              });
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              });
              
            }
            this.getFirstData();
          });
        })
        .catch(function() {});
    },
    // 批量删除
    DeleteConfirm() {
      if (this.multipleSelection.length <= 0) {
        this.$message({
          type: 'error',
          message: 'Select the content to be deleted'
        });
        this.value = '';
        return false;
      } else {
        var pool = null;
        if (this.skuID.constructor === String) {
          // this.skuId = this.skuID.toString();
          pool = this.skuID.split(',');
          pool = pool.map(function(item, index, array) {
            return item - 0;
          });
        } else if (this.skuID.constructor !== String) {
          pool = this.skuID;
          pool = pool.map(function(item, index, array) {
            return item - 0;
          });
        } else if (this.skuID === null) {
          this.$message({
            message: 'Product Pool NO cannot be empty ',
            type: 'error'
          });
          return false;
        }

        // if (this.skuID) {
        //   pool = this.skuID.split(',');
        //   pool = pool.map(function(item, index, array) {
        //     return item - 0;
        //   });
        // } else if (this.skuID === null) {
        //   this.$message({
        //     message: 'Product Pool NO cannot be empty ',
        //     type: 'error'
        //   });
        //   return false;
        // }
        var lists = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          const element = this.multipleSelection[i];
          lists.push(element.contractNo);
        }
        // console.log(lists)
        removePoolAndSkuRelations({
          poolId: Number(this.spanId),
          skuIds: pool,
          contractNos: lists
        }).then(res => {
          if (res.code == 200) {
            this.$message({ message: 'Operation succeeded', type: 'success' });
          } else {
            this.$message.error(res.msg);
          }
        });
        this.value = '';
        this.skuID = null;
        this.spanId = null;
        this.dialogVisible = false;
      }
    },
    //复制id到商品修改里面
    Copy(row) {
      row.flang = false;
      this.$router.push({
        name: 'editCommodity pools',
        query: {
          flang:row.flang,
          id:row.id,
          contractNo:row.contractNo
        }
      });
    },
    //下载当前页所有数据
    exportExcel() {
      poolExports(this.form).then(res => {
        var downloadUrl = window.URL.createObjectURL(res);
        var anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.download = 'dataDownload.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(res);
      });
    },
    //导出当前一行数据
    Export(row) {
      poolOneExports({ id: row.id }).then(res => {
        var downloadUrl = window.URL.createObjectURL(res);
        var anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.download = 'A single data.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(res);
      });
      //console.log(row);
    },
    // 上方查询条件隐藏显示
    selectHiden() {
      this.hide = !this.hide;
    },
    changes(i) {
      if (i == '2') {
        this.centerDialogVisible = true;
        // setTimeout(() => {
        //   this.getpush();
        // }, 3000);
      } else {
        this.spanId = this.multipleSelection.map(item => {
          return item.id;
        });
        // if (process.env.VUE_APP_BASE_API == '/apiid') {
        //   console.log('印尼');
        //   this.spanId = this.multipleSelection.map(item => {
        //     return item.id;
        //   });
        // } else {
        //   console.log('泰国');
        //   this.spanId = this.multipleSelection.map(item => {
        //     return item.skuId;
        //   });
        // }

        this.poolTitle = 'Batch Deleted SKU from Product Pool';
        this.dialogVisible = true;
        this.buttonAdd = false;
        this.buttonDelete = true;
      }
    },
    // 控制弹框的
    handleClose(done) {
      this.$confirm('Confirm closure?')
        .then(_ => {
          done();
          this.skuID = [];
          this.spanId = null;
          this.toggleSelection();
          this.value = '';
        })
        .catch(_ => {});
    },
    // 新增跟删除类目点击叉号
    centerDialogVisibleClose(done) {
      this.$confirm('Confirm closure?')
        .then(_ => {
          done();
          this.$refs.allSelectList.clearSelect();
          this.toggleSelection();
          this.value = '';
        })
        .catch(_ => {});
    },
    // 添加
    addCategorySku() {
      if(this.multipleSelection.length>0) {
        this.spanId = this.multipleSelection.map(item => {
          return item.id;
        });
        this.buttonDelete = false;
        this.buttonAdd = true;
        this.dialogVisible = true;
        this.poolTitle = 'Batch Add SKU from Product Pool';
      } else {
        this.$message({
          message: 'please selelct product pool',
        });
      }
    },
    // 控制三级类目展开
    addCategory() {
      this.centerDialogVisible = true;
      this.addCategoryDialog=true
      // setTimeout(() => {
      //   this.getpush();
      // }, 3000);
    },
    // 控制三级类选中的多少类
    allSelectLists(data) {
      this.allSelectListsr = data;
    },
    // 上传选中的类id
    updateCates() {
      // if (this.allSelectListsr.length > 200) {
      //   this.$message.waring('Less than 200 species were selected');
      // } else {
      var id = null;
      this.multipleSelection2.forEach(item => {
        id = item.id;
      });
      this.lastSelectLists = this.$refs.allSelectList.lastSelectLists;
      if(this.addCategoryDialog){
        updateCate({
          userAgent: 1,
          cateIds: String(this.lastSelectLists),
          poolId: id
        }).then(res => {
          this.$refs.allSelectList.clearSelect();
          this.$message({ message: 'Operation succeeded', type: 'success' });
        });
      }else{
        deleteCate({
          userAgent: 1,
          cateIds: String(this.lastSelectLists),
          poolId: id
        }).then(res => {
          this.$refs.allSelectList.clearSelect();
          this.$message({ message: 'Operation succeeded', type: 'success' });
        });
      }
      
      this.centerDialogVisible = false;
      this.toggleSelection();
      this.value = '';
    },
    // 弹窗下载模板
    DownExcel() {
      download(this.excel).then(res => {
        var downloadUrl = window.URL.createObjectURL(res);
        var anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.download = 'Download the template.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(res);
      });
    },
    // 文件上传成功是回填
    handleAvatarSuccess(res) {
      const number = res.data.map(item => {
        return item.skuId;
      });
      this.skuID = number;
    },
    Confirm() {
      var pool = null;
      if (this.skuID === null || this.skuID.length==0) {
        this.$message({
          message: 'SKU cannot be empty ',
          type: 'error'
        });
        return false;
      }

      if (this.skuID.constructor === String) {
        // this.skuId = this.skuID.toString();
        console.log(this.skuID.constructor === String);
        console.log(this.skuID.constructor === Array);
        // console.log(Object.prototype.toString.call(this.skuID));
        pool = this.skuID.split(',');
        pool = pool.map(function(item, index, array) {
          return item - 0;
        });
      } else if (this.skuID.constructor !== String) {
        pool = this.skuID;
        pool = pool.map(function(item, index, array) {
          return item - 0;
        });
      } else if (this.skuID === null) {
        this.$message({
          message: 'Product Pool NO cannot be empty ',
          type: 'error'
        });
        return false;
      }

      addPoolSkuIdss({
        poolIds: this.spanId,
        skuIds: pool,
      }).then(res => {
        if (res.code == 500) {
          this.$message.error(res.msg);
        } else {
          this.$message.success('Operation succeeded');
        }
      });
      this.skuID = null;
      this.spanId = null;
      this.dialogVisible = false;
    },
    // 回显类目
    getpush(res) {
      var id = null;
      this.multipleSelection2.forEach(item => {
        id = item.id;
      });
      getCategoryTree({
        userAgent: 1,
        poolId: id
      }).then(res => {
        this.datas = res;
      });
    },
    // 批量添加取消的时候输入的skuID清除
    BatchaddCancel() {
      this.dialogVisible = false;
      this.skuID = [];
      this.value = '';
    }
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
* {
  font-size: 14px !important;
  font-family: ArialMT;
  font-weight: normal;
}
.fixed_form {
  .el-input {
    width: 200px;
    margin-right: 30px;
  }
}
.poolList {
  .tableHead {
    background: '#e8f1fe';
    font-size: 14px !important;
    font-weight: normal;
  }
  .el-input {
    width: 200px;
    margin-right: 30px;
  }
  ::v-deep .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
  .el-pagination__editor.el-input {
    width: 50px;
  }
  .serch_box {
    .el-button {
      ::v-deep .el-icon-search {
        font-size: 14px;
      }
    }
    .pageWrapper {
      /deep/.el-pagination {
        margin-top: 10px;
        float: right !important;
      }
      height: 45px;
    }
  }
  .fixed_form {
    width: 80%;
    ::v-deep .el-form-item__content {
      width: 230px;
    }
    /deep/ .el-input__inner {
      width: 187px !important;
    }
  }

  .heard_box {
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 10px;
    div {
      label {
        margin-right: 5px;
      }
    }
  }
  .serch_box {
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: #fff;
    padding: 20px;
    box-sizing: border-box;
  }
  .panel-body {
    margin-top: 20px;
  }
  .xiala {
    float: right;
    width: 110px;
    color: #2262ff;
    margin: 8px 50px 0 0;
    i {
      margin-left: 4px;
    }
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #2262ff;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .el-dropdown {
    vertical-align: top;
  }
  .el-dropdown + .el-dropdown {
    margin-left: 15px;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .buttonr {
    margin-top: 10px;
  }
  .dialog-footer {
    .el-button {
      line-height: 0px;
    }
  }
  .fromButton {
    .el-button {
      line-height: 0 !important;
      float: right;
      margin-left: 15px;
    }
  }
  .dialogTextarea {
    width: 100%;
    height: 66px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(183, 188, 201, 0.65);
    border-radius: 4px;
  }
  .span {
    margin: 40px 10px;
  }
  .exportExcel {
    float: right;
  }
  .CategoryOption {
    margin-left: 10px;
  }
}
/deep/.el-button {
  // padding: 0 12px !important;
  //width: 100px;
  font-family: ArialMT;
  font-size: 14px;
}

::v-deep .el-button--primary {
  color: #fff;
  background-color: #2262ff;
  border-color: #2262ff;
}
::v-deep .el-button--text {
  color: #2262ff;
}
/* ::v-deep .el-link--primary {
    color: #2262ff;
  } */

::v-deep .el-radio-group {
  .el-radio__label {
    color: #242526;
  }
  .is-checked {
    .el-radio__inner {
      background-color: #2262ff;
    }
    .el-radio__label {
      color: #242526;
    }
  }
}
.button:first-child {
  margin-left: 26px;
}
::v-deep .el-table {
  .cell {
    white-space: pre-wrap;
    word-break: keep-all;
  }
}

::v-deep .el-pager {
  li {
    border: 0;
  }
}
.box-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .button-box {
    display: flex;
    .button-right {
      display: flex;
    }
  }
}
</style>
