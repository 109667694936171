// import * as types from "../../../stores/types";
// import { mapActions } from "vuex";
const getType = o => Object.prototype.toString.call(o).slice(8, -1);

export default {
  methods: {
    // ...mapActions({
    //   toast: types.TOAST
    // }),
    // ts(name, num) {
    //   return this.$t(`promotion.binding.${name}`, { num });
    // },
    // success(content) {
    //   const ct = content || this.ts('message.success');
    //   this.toast({
    //     type: 'success',
    //     content: ct
    //   });
    // },
    // failed(content) {
    //   const ct = content || this.ts('message.failed');
    //   this.toast({
    //     type: 'error',
    //     content: ct
    //   });
    // },
    deepClone(obj) {
      let result;
      if (getType(obj) === 'Object') {
        result = {};
        for (const key in obj) {
          if (getType(obj[key]) === 'Object' || getType(obj[key]) === 'Array') {
            result[key] = this.deepClone(obj[key]);
          } else {
            result[key] = obj[key];
          }
        }
      } else if (Object.prototype.toString.call(obj).slice(8, -1) === 'Array') {
        result = [];
        for (const key of obj) {
          if (getType(obj[key]) === 'Object' || getType(obj[key]) === 'Array') {
            result.push(this.deepClone(key));
          } else {
            result.push(this.deepClone(key));
          }
        }
      } else {
        return obj;
      }
      return result;
    }
  }
};
